
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.landing-page {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.landing-page .modal {
  color: black;
  font-size: 15px !important;
}

.landing-section {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 0;
}

.landing-section.light {
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 0;
}

.landing-section-content {
  text-align: center;
  padding: 2em 1em;
  margin: 2em 1em;
  width: 60%;
}

.landing-section-content.full {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-right: 0;
  width: 80%;
}

.landing-section-content {
  padding-top: .5em;
  padding-bottom: .5em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.text-purple {
  color: #54319B;
}

.text-yellow {
  color: #FECA66;
}

.text-danger {
  color: red;
}

.text-muted {
  color: #757575
}

.text {
  display: block;
  font-size: 1.65em;
  line-height: 1.2em;
  margin-bottom: .5em;
}

#logo {
  height: 15em;
  width: 30em;
}

h1.header {
  font-size: 3.2em;
  font-weight: bolder;
}

h3 {
  font-size: 2em;
}

.sub-header {
  position: relative;
}

p.paragraph {
  font-size: 1.3em;
  line-height: 1.1em;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.list.two-column .list-item {
  width: 50%;
  padding: 0 2.5%;
}

span.list-item {
  font-size: 1.5em;
  display: flex;
  margin-bottom: .3em;
  align-items: center;
}

span.list-item .landing-checkmark {
  font-size: 1.5em;
  margin-right: .25em;
}

.call-to-action {
  display: flex;
  flex-direction: row;
}

.call-to-action .left-two-thirds {
  width: 66.66%;
  text-align: left;
}

.call-to-action .right-third {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col-half {
  width: 50%;
}

.col-third {
  width: 33.33%;
}

.col-two-thirds {
  width: 66.66%;
}

.v-split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h-split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.above {
  z-index: 100;
}

.below {
  z-index: -100;
}

@media (max-width: 600px) {
  .landing-page {
    font-size: 10px;
  }

  .call-to-action {
    flex-direction: column;
  }

  .call-to-action .left-two-thirds {
    width: 100%;
    text-align: center;
  }

  .call-to-action .right-third {
    width: 100%;
  }

  .col-half, .col-third, .col-two-thirds {
    width: 100%;
    margin-bottom: 3em;
  }

  .list.two-column .list-item {
    width: 100%;
  }
}