@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600&family=Poppins:wght@300;400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  -moz-osx-font-smoothing: grayscale;
  color:#222222

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

h1,
h2,
h3,
h4,
.titel {
  font-family: 'Archivo', sans-serif;
}