.logo {
    display: block;
    width: 130px;
    border: 1px solid #ddd;
    margin-left: 15px;
    position: relative;
    z-index: 2;
    backface-visibility: hidden;
    line-height: 0;
}

.logo img {
    width: 100%;
}
@media screen and (max-width:768px) {
    .logo{
        width: 100px;
    }
}