.card {
    box-shadow: 1px 1px 10px #ddd;
    padding: 20px 15px;
    border-radius: 5px;
    margin: 0 1%;
}

.inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inline .content {
    width: calc(100% - 50px);
    padding-left: 20px;
}
.inline .content p{
    font-size: 14px;
}


.inline .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin-bottom: 0;
}



.icon {
    width: 80px;
    height: 80px;
    background: #A020EF;
    padding: 15px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    transition: .1s linear;
}

.icon:hover {
    background: #a020efdf;
    transform: scale(1.04);
}

.titel {
    font-size: 20px;
    text-align: center;
    color: #222222;
    margin-bottom: 10px;
}

.content p {
    font-size: 15px;
}



.icon img {
    width: 100%;
}

@media screen and (max-width:768px) {
    .card {
        width: 100% !important;
        margin-top: 20px;
    }

    .titel {
        font-size: 24px;
    }
}