.faqhdr {
    background: #A020EF;
    padding: 13px;
    position: relative;
    color: #fff;
    cursor: pointer;
    user-select: none;
    padding-right: 26px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.513);
}

.faqhdr:hover {
    background: #a020efd0;
}

.faqhdr p {
    font-size: 18px;
}

.faqhdr::after {
    content: '\f078';
    font-family: "Font Awesome 5 Pro";
    color: #fff;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.faqhdr.open::after{
    transition: .2s linear;
    transform: rotate(180deg) translateY(50%);

}

.faqBody {
    padding: 0;
    background: #f9f9f9;
    height: 0;
    overflow: hidden;
}

.faqBody p {
    font-size: 15px;
}

.faqBody.open {
    padding: 13px;
    height: auto;
}



@media screen and (max-width:768px) {
    .faqBody p {
        font-size: 13px;
    }
    .faqhdr p {
        font-size: 15px;
    }
}