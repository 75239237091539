@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600&family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);

.full-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.flex-row {
  flex-direction: row !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  -moz-osx-font-smoothing: grayscale;
  color:#222222

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

h1,
h2,
h3,
h4,
.titel {
  font-family: 'Archivo', sans-serif;
}
.header_logo__frlAE {
    display: block;
    width: 130px;
    border: 1px solid #ddd;
    margin-left: 15px;
    position: relative;
    z-index: 2;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    line-height: 0;
}

.header_logo__frlAE img {
    width: 100%;
}
@media screen and (max-width:768px) {
    .header_logo__frlAE{
        width: 100px;
    }
}
#hero_hero__3asoV {
    margin-top: -15px;
}

.hero_heroWraper__pEWJg {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.hero_hrRght__14Kas {
    background: #A020EF;
    padding: 50px 30px;
    height: auto;
    width: 38%;
}

.hero_hrLft__14bXU {
    height: auto;
    background: url(/static/media/digital-increasing-bar-graph-with-businessman-hand-overlay.d4dfd420.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 62%;
    background-position: left top;
}


.hero_hrRght__14Kas h1 {
    color: #fff;
    font-size: 28px;
    margin-bottom: 25px;
}

.hero_hrRght__14Kas p {
    color: rgb(255, 255, 255);
    font-size: 15px;
    margin-top: 10px;
}

.hero_hrRght__14Kas span {
    display: block;
    margin-top: 20px;
    font-size: 17px;
    color: #fff;
}
.hero_button__o_2Ih{
    margin-top: 20px;
}
@media screen and (max-width:768px) {
    .hero_hrLft__14bXU {
        height: 300px;
    }

    .hero_hrRght__14Kas,
    .hero_hrLft__14bXU {
        width: 100%;
    }

    .hero_hrRght__14Kas h1 {
        font-size: 22px;
    }

    .hero_hrRght__14Kas p {
        font-size: 14px;
    }

    .hero_hrRght__14Kas span {
        font-size: 15px;
    }


}
.button_button__8VXci {
    background: #fff;
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    transition: .2s linear;
}

.button_btnBlue__Z4wMA {
    background: #A020EF !important;
    color: #fff !important;
}

.button_btnBlue__Z4wMA:hover {
    background: #fff !important;
    color: #A020EF !important;
}

.button_button__8VXci:hover {
    background: #A020EF;
    color: #fff;
}

.button_button__8VXci i {
    padding-left: 8px;
}
#about_about__3BsWF .about_container__35ZZ8 {
    padding: 80px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
#about_about__3BsWF .about_container__35ZZ8::after{
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    width: 8%;
    height: 25%;
    background: url(/static/media/dotedshape.8cf39d6b.png);
    background-size: cover;
    background-repeat: repeat;
    z-index: -1;
}
#about_about__3BsWF .about_container__35ZZ8::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 8%;
    height: 25%;
    background: url(/static/media/dotedshape.8cf39d6b.png);
    background-size: cover;
    background-repeat: repeat;
    z-index: -1;
}
.about_sectitel__3Kt3c {
    text-align: center;
    font-size: 30px;
    color: #222222;
}

.about_aboutdes__2CJn9 {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}
@media screen and (max-width:768px) {
    .about_sectitel__3Kt3c {
        font-size: 24px;
    }
    .about_aboutdes__2CJn9{
        font-size: 14px;
    }
}
#whyus_whyus__2I6Ua {
    padding: 50px 0;
    background: #f9f9f9;
}

.whyus_wrapper__JUjdV {
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}

.whyus_sectitel__1y7bP {
    text-align: center;
    font-size: 30px;
    color: #222222;
}
@media screen and (max-width:768px) {
    .whyus_sectitel__1y7bP {
        font-size: 24px;
    }
}
.card_card__27jm0 {
    box-shadow: 1px 1px 10px #ddd;
    padding: 20px 15px;
    border-radius: 5px;
    margin: 0 1%;
}

.card_inline__1P9vH {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_inline__1P9vH .card_content__2MoXc {
    width: calc(100% - 50px);
    padding-left: 20px;
}
.card_inline__1P9vH .card_content__2MoXc p{
    font-size: 14px;
}


.card_inline__1P9vH .card_icon__1BNMc {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin-bottom: 0;
}



.card_icon__1BNMc {
    width: 80px;
    height: 80px;
    background: #A020EF;
    padding: 15px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    transition: .1s linear;
}

.card_icon__1BNMc:hover {
    background: #a020efdf;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}

.card_titel__1w37W {
    font-size: 20px;
    text-align: center;
    color: #222222;
    margin-bottom: 10px;
}

.card_content__2MoXc p {
    font-size: 15px;
}



.card_icon__1BNMc img {
    width: 100%;
}

@media screen and (max-width:768px) {
    .card_card__27jm0 {
        width: 100% !important;
        margin-top: 20px;
    }

    .card_titel__1w37W {
        font-size: 24px;
    }
}
#profit-sharing_profit__1ljd0 {
    padding: 50px 0;
    background: #f9f9f9;
}

.profit-sharing_wrapper__15Ldx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profit-sharing_profitLft__3h5qe {
    width: 50%;
    padding-right: 20px;
}

.profit-sharing_sectitel__3iMVX {
    font-size: 30px;
}

.profit-sharing_profitLft__3h5qe p {
    margin-top: 25px;
    font-size: 15px;
}

.profit-sharing_profitrght__RYa23 {
    width: 50%;
    background: url(/static/media/revenue-sharing.2f652219.jpg);
    background-size: cover;
    background-position: top center;
    height: 400px;
    border-radius: 30px;
}

@media screen and (max-width:768px) {
    .profit-sharing_sectitel__3iMVX {
        font-size: 24px;
        text-align: center;
    }

    .profit-sharing_profitLft__3h5qe {
        width: 100%;
    }

    .profit-sharing_profitLft__3h5qe p {
        font-size: 14px;
        text-align: center;
    }

    .profit-sharing_profitrght__RYa23 {
        display: none;
    }
}
.earn-share-points_sectitel__1qOWj {
    text-align: center;
    font-size: 30px;
}

#earn-share-points_ernpoints__BeH2R {
    padding: 80px 0;
}

.earn-share-points_wrapper__2-kLS {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    margin-top: 30px;
}
@media screen and (max-width:768px) {
    .earn-share-points_sectitel__1qOWj {
        font-size: 24px;
    }
}
.single-faq_faqhdr__1vQyq {
    background: #A020EF;
    padding: 13px;
    position: relative;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    padding-right: 26px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.513);
}

.single-faq_faqhdr__1vQyq:hover {
    background: #a020efd0;
}

.single-faq_faqhdr__1vQyq p {
    font-size: 18px;
}

.single-faq_faqhdr__1vQyq::after {
    content: '\f078';
    font-family: "Font Awesome 5 Pro";
    color: #fff;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.single-faq_faqhdr__1vQyq.single-faq_open__e2Ir5::after{
    transition: .2s linear;
    -webkit-transform: rotate(180deg) translateY(50%);
            transform: rotate(180deg) translateY(50%);

}

.single-faq_faqBody__1Ri4C {
    padding: 0;
    background: #f9f9f9;
    height: 0;
    overflow: hidden;
}

.single-faq_faqBody__1Ri4C p {
    font-size: 15px;
}

.single-faq_faqBody__1Ri4C.single-faq_open__e2Ir5 {
    padding: 13px;
    height: auto;
}



@media screen and (max-width:768px) {
    .single-faq_faqBody__1Ri4C p {
        font-size: 13px;
    }
    .single-faq_faqhdr__1vQyq p {
        font-size: 15px;
    }
}
#faq_faqs__3YRWM{
    padding: 50px 0;
    background: url(/static/media/faqbg.b3bf31e7.png);
    background-size: cover;
    background-repeat: no-repeat;
}
#faq_faqs__3YRWM .faq_container__35PF7{
max-width: 800px;
}
.faq_sectitel__1Nu49 {
    text-align: center;
    font-size: 30px;
    color: #222222;
    margin-bottom: 50px;
}


.landing-page {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.landing-page .modal {
  color: black;
  font-size: 15px !important;
}

.landing-section {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 0;
}

.landing-section.light {
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 0;
}

.landing-section-content {
  text-align: center;
  padding: 2em 1em;
  margin: 2em 1em;
  width: 60%;
}

.landing-section-content.full {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-right: 0;
  width: 80%;
}

.landing-section-content {
  padding-top: .5em;
  padding-bottom: .5em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.text-purple {
  color: #54319B;
}

.text-yellow {
  color: #FECA66;
}

.text-danger {
  color: red;
}

.text-muted {
  color: #757575
}

.text {
  display: block;
  font-size: 1.65em;
  line-height: 1.2em;
  margin-bottom: .5em;
}

#logo {
  height: 15em;
  width: 30em;
}

h1.header {
  font-size: 3.2em;
  font-weight: bolder;
}

h3 {
  font-size: 2em;
}

.sub-header {
  position: relative;
}

p.paragraph {
  font-size: 1.3em;
  line-height: 1.1em;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.list.two-column .list-item {
  width: 50%;
  padding: 0 2.5%;
}

span.list-item {
  font-size: 1.5em;
  display: flex;
  margin-bottom: .3em;
  align-items: center;
}

span.list-item .landing-checkmark {
  font-size: 1.5em;
  margin-right: .25em;
}

.call-to-action {
  display: flex;
  flex-direction: row;
}

.call-to-action .left-two-thirds {
  width: 66.66%;
  text-align: left;
}

.call-to-action .right-third {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col-half {
  width: 50%;
}

.col-third {
  width: 33.33%;
}

.col-two-thirds {
  width: 66.66%;
}

.v-split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h-split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.above {
  z-index: 100;
}

.below {
  z-index: -100;
}

@media (max-width: 600px) {
  .landing-page {
    font-size: 10px;
  }

  .call-to-action {
    flex-direction: column;
  }

  .call-to-action .left-two-thirds {
    width: 100%;
    text-align: center;
  }

  .call-to-action .right-third {
    width: 100%;
  }

  .col-half, .col-third, .col-two-thirds {
    width: 100%;
    margin-bottom: 3em;
  }

  .list.two-column .list-item {
    width: 100%;
  }
}
