#whyus {
    padding: 50px 0;
    background: #f9f9f9;
}

.wrapper {
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sectitel {
    text-align: center;
    font-size: 30px;
    color: #222222;
}
@media screen and (max-width:768px) {
    .sectitel {
        font-size: 24px;
    }
}