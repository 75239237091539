#about .container {
    padding: 80px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
#about .container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    width: 8%;
    height: 25%;
    background: url('../../assets/img/dotedshape.png');
    background-size: cover;
    background-repeat: repeat;
    z-index: -1;
}
#about .container::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 8%;
    height: 25%;
    background: url('../../assets/img/dotedshape.png');
    background-size: cover;
    background-repeat: repeat;
    z-index: -1;
}
.sectitel {
    text-align: center;
    font-size: 30px;
    color: #222222;
}

.aboutdes {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}
@media screen and (max-width:768px) {
    .sectitel {
        font-size: 24px;
    }
    .aboutdes{
        font-size: 14px;
    }
}