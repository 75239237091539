.sectitel {
    text-align: center;
    font-size: 30px;
}

#ernpoints {
    padding: 80px 0;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    margin-top: 30px;
}
@media screen and (max-width:768px) {
    .sectitel {
        font-size: 24px;
    }
}