#profit {
    padding: 50px 0;
    background: #f9f9f9;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profitLft {
    width: 50%;
    padding-right: 20px;
}

.sectitel {
    font-size: 30px;
}

.profitLft p {
    margin-top: 25px;
    font-size: 15px;
}

.profitrght {
    width: 50%;
    background: url('../../assets/img/revenue-sharing.jpg');
    background-size: cover;
    background-position: top center;
    height: 400px;
    border-radius: 30px;
}

@media screen and (max-width:768px) {
    .sectitel {
        font-size: 24px;
        text-align: center;
    }

    .profitLft {
        width: 100%;
    }

    .profitLft p {
        font-size: 14px;
        text-align: center;
    }

    .profitrght {
        display: none;
    }
}