#hero {
    margin-top: -15px;
}

.heroWraper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.hrRght {
    background: #A020EF;
    padding: 50px 30px;
    height: auto;
    width: 38%;
}

.hrLft {
    height: auto;
    background: url('../../assets/img/digital-increasing-bar-graph-with-businessman-hand-overlay.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 62%;
    background-position: left top;
}


.hrRght h1 {
    color: #fff;
    font-size: 28px;
    margin-bottom: 25px;
}

.hrRght p {
    color: rgb(255, 255, 255);
    font-size: 15px;
    margin-top: 10px;
}

.hrRght span {
    display: block;
    margin-top: 20px;
    font-size: 17px;
    color: #fff;
}
.button{
    margin-top: 20px;
}
@media screen and (max-width:768px) {
    .hrLft {
        height: 300px;
    }

    .hrRght,
    .hrLft {
        width: 100%;
    }

    .hrRght h1 {
        font-size: 22px;
    }

    .hrRght p {
        font-size: 14px;
    }

    .hrRght span {
        font-size: 15px;
    }


}