#faqs{
    padding: 50px 0;
    background: url('../../assets/img/faqbg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
#faqs .container{
max-width: 800px;
}
.sectitel {
    text-align: center;
    font-size: 30px;
    color: #222222;
    margin-bottom: 50px;
}
