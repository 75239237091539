.button {
    background: #fff;
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    transition: .2s linear;
}

.btnBlue {
    background: #A020EF !important;
    color: #fff !important;
}

.btnBlue:hover {
    background: #fff !important;
    color: #A020EF !important;
}

.button:hover {
    background: #A020EF;
    color: #fff;
}

.button i {
    padding-left: 8px;
}